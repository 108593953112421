var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "title-container" },
        [_c("Title", { attrs: { title: _vm.title } })],
        1
      ),
      _vm.isLoader
        ? _c("Loader", { attrs: { loading: _vm.isLoader } })
        : _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass: "flex justify-between px-6 py-3 my-2",
                staticStyle: {
                  "background-color": "#0090ba",
                  "border-radius": "0.5rem"
                }
              },
              [
                _c("div", { staticClass: "flex seach-icon w-1/2" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.serachQuery,
                        expression: "serachQuery"
                      }
                    ],
                    staticClass: "border p-2 w-full pl-8 rounded",
                    attrs: {
                      type: "text",
                      placeholder: "Search by name or email"
                    },
                    domProps: { value: _vm.serachQuery },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.serachQuery = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 20 20"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "data-v-09f89cc6": "",
                          d:
                            "M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"
                        }
                      })
                    ]
                  )
                ]),
                _c(
                  "Button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.addUser()
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "20",
                          height: "20",
                          viewBox: "0 0 24 24"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M9.602 3.7c-1.154 1.937-.635 5.227 1.424 9.025.93 1.712.697 3.02.338 3.815-.982 2.178-3.675 2.799-6.525 3.456-1.964.454-1.839.87-1.839 4.004h-1.995l-.005-1.241c0-2.52.199-3.975 3.178-4.663 3.365-.777 6.688-1.473 5.09-4.418-4.733-8.729-1.35-13.678 3.732-13.678 3.321 0 5.97 2.117 5.97 6.167 0 3.555-1.949 6.833-2.383 7.833h-2.115c.392-1.536 2.499-4.366 2.499-7.842 0-5.153-5.867-4.985-7.369-2.458zm13.398 15.3h-3v-3h-2v3h-3v2h3v3h2v-3h3v-2z"
                          }
                        })
                      ]
                    ),
                    _vm._v("Create User")
                  ]
                )
              ],
              1
            ),
            _c("div", { staticClass: "mt-6 shadow sm:rounded-lg" }, [
              _c("div", { staticClass: "bg p-4" }, [
                _c("div", { staticClass: "title" }, [_vm._v("Users")]),
                _c("div", { staticClass: "flex flex-col pt-4" }, [
                  _c("div", { staticClass: "flex-grow table-scroll" }, [
                    _c("table", { staticClass: "relative w-full" }, [
                      _c("thead", [
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticClass:
                                "sticky top-0 px-6 py-3 text-left header-color"
                            },
                            [_vm._v(" Name ")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass:
                                "sticky top-0 px-6 py-3 text-left header-color"
                            },
                            [_vm._v(" Email ")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass:
                                "sticky top-0 px-6 py-3 text-left header-color"
                            },
                            [_vm._v(" Role ")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass:
                                "sticky top-0 px-6 py-3 text-left header-color"
                            },
                            [_vm._v(" Countries ")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass:
                                "sticky top-0 px-6 py-3 text-left header-color"
                            },
                            [_vm._v(" Status ")]
                          ),
                          _c("th", {
                            staticClass:
                              "sticky top-0 px-6 py-3 text-left header-color"
                          })
                        ])
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(_vm.filterUsers, function(user, i) {
                            return _c("tr", { key: i }, [
                              _c(
                                "td",
                                { staticClass: "px-6 py-2 whitespace-nowrap" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex items-center" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-shrink-0 people-icon"
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "16",
                                                height: "16",
                                                viewBox: "0 0 24 24"
                                              }
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  d:
                                                    "M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _c("div", { staticClass: "ml-4" }, [
                                        _c(
                                          "div",
                                          {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.highlightMatches(
                                                  user.UserName
                                                )
                                              )
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(user.UserName) + " "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "px-6 py-2 whitespace-nowrap" },
                                [
                                  _c(
                                    "div",
                                    {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.highlightMatches(user.UserEmail)
                                        )
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(user.UserEmail) + " ")]
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "px-6 py-2 whitespace-nowrap" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(!user.Role ? "--" : user.Role) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "px-6 py-2 whitespace-nowrap" },
                                [
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          !user.ActiveCountries
                                            ? "--"
                                            : user.ActiveCountries
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "px-6 py-2 whitespace-nowrap" },
                                [
                                  user.Active === 1
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "px-4 py-1 inline-flex text-sm leading-5 font-semibold rounded-full bg-green-400 text-green-800"
                                        },
                                        [_vm._v(" Active ")]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "px-4 py-1 inline-flex text-sm leading-5 font-semibold rounded-full bg-red-400 text-red-800"
                                        },
                                        [_vm._v(" Inactive ")]
                                      )
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "px-6 py-2 whitespace-nowrap" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-between actions"
                                    },
                                    [
                                      _c(
                                        "IconButton",
                                        {
                                          attrs: { buttonType: _vm.buttonType },
                                          on: {
                                            click: function($event) {
                                              return _vm.editUser(user)
                                            }
                                          }
                                        },
                                        [_vm._v("Edit")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "IconButton",
                                        {
                                          attrs: { buttonType: "delete" },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeUser(user)
                                            }
                                          }
                                        },
                                        [_vm._v("Remove")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ])
                          }),
                          _vm.filterUsers.length === 0
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "px-6 py-3",
                                    staticStyle: {
                                      "text-align": "center",
                                      "font-weight": "bold"
                                    },
                                    attrs: { colspan: "5" }
                                  },
                                  [_vm._v(" No User(s) found ")]
                                )
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }