import axios from "../http-common";
import loggerService from "./logger";

export default {
    getUsers() {
        return axios
            .get(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/GetAllUsers`)
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getRoles() {
        return axios
            .get(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/GetUserRoles`)
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    addOrUpdateUser(payLoad) {
        return axios.post(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/AddorUpdateUser`, payLoad)
            .catch((error) => {
                loggerService.logError(error);
            });
    },
}