<template>
  <div class="page-container">
    <div class="title-container">
      <Title :title="title" />
    </div>
    <Loader :loading="isLoader" v-if="isLoader" />
    <div v-else class="container">
      <div
        class="flex justify-between px-6 py-3 my-2"
        style="background-color: #0090ba; border-radius: 0.5rem"
      >
        <div class="flex seach-icon w-1/2">
          <input
            type="text"
            placeholder="Search by name or email"
            class="border p-2 w-full pl-8 rounded"
            v-model="serachQuery"
          />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
              data-v-09f89cc6=""
              d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"
            ></path>
          </svg>
        </div>

        <Button @click="addUser()"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path
              d="M9.602 3.7c-1.154 1.937-.635 5.227 1.424 9.025.93 1.712.697 3.02.338 3.815-.982 2.178-3.675 2.799-6.525 3.456-1.964.454-1.839.87-1.839 4.004h-1.995l-.005-1.241c0-2.52.199-3.975 3.178-4.663 3.365-.777 6.688-1.473 5.09-4.418-4.733-8.729-1.35-13.678 3.732-13.678 3.321 0 5.97 2.117 5.97 6.167 0 3.555-1.949 6.833-2.383 7.833h-2.115c.392-1.536 2.499-4.366 2.499-7.842 0-5.153-5.867-4.985-7.369-2.458zm13.398 15.3h-3v-3h-2v3h-3v2h3v3h2v-3h3v-2z"
            /></svg
          >Create User</Button
        >
      </div>

      <div class="mt-6 shadow sm:rounded-lg">
        <div class="bg p-4">
          <div class="title">Users</div>
          <div class="flex flex-col pt-4">
            <div class="flex-grow table-scroll">
              <table class="relative w-full">
                <thead>
                  <tr>
                    <th class="sticky top-0 px-6 py-3 text-left header-color">
                      Name
                    </th>
                    <th class="sticky top-0 px-6 py-3 text-left header-color">
                      Email
                    </th>
                    <th class="sticky top-0 px-6 py-3 text-left header-color">
                      Role
                    </th>
                    <th class="sticky top-0 px-6 py-3 text-left header-color">
                      Countries
                    </th>
                    <th class="sticky top-0 px-6 py-3 text-left header-color">
                      Status
                    </th>
                    <th
                      class="sticky top-0 px-6 py-3 text-left header-color"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, i) in filterUsers" :key="i">
                    <td class="px-6 py-2 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="flex-shrink-0 people-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"
                            />
                          </svg>
                        </div>
                        <div class="ml-4">
                          <div v-html="highlightMatches(user.UserName)">
                            {{ user.UserName }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-2 whitespace-nowrap">
                      <div v-html="highlightMatches(user.UserEmail)">
                        {{ user.UserEmail }}
                      </div>
                    </td>
                    <td class="px-6 py-2 whitespace-nowrap">
                      {{ !user.Role ? "--" : user.Role }}
                    </td>
                    <td class="px-6 py-2 whitespace-nowrap">
                      <div>
                        {{
                          !user.ActiveCountries ? "--" : user.ActiveCountries
                        }}
                      </div>
                    </td>
                    <td class="px-6 py-2 whitespace-nowrap">
                      <span
                        v-if="user.Active === 1"
                        class="px-4 py-1 inline-flex text-sm leading-5 font-semibold rounded-full bg-green-400 text-green-800"
                      >
                        Active
                      </span>
                      <span
                        v-else
                        class="px-4 py-1 inline-flex text-sm leading-5 font-semibold rounded-full bg-red-400 text-red-800"
                      >
                        Inactive
                      </span>
                    </td>
                    <td class="px-6 py-2 whitespace-nowrap">
                      <div class="flex justify-between actions">
                        <IconButton
                          @click="editUser(user)"
                          :buttonType="buttonType"
                          >Edit</IconButton
                        >&nbsp;&nbsp;&nbsp;
                        <IconButton
                          :buttonType="'delete'"
                          @click="removeUser(user)"
                          >Remove</IconButton
                        >
                      </div>
                    </td>
                  </tr>
                  <tr v-if="filterUsers.length === 0">
                    <td
                      colspan="5"
                      class="px-6 py-3"
                      style="text-align: center; font-weight: bold"
                    >
                      No User(s) found
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import loggerService from "../../services/logger";
import uAmService from "../../services/uam";
import { Bus } from "../../eventBus";
const User = () => import(/* webpackPrefetch: true */ "./User");
import userService from "../../services/uam";
import authentication from "../../authentication";

export default {
  components: {
    Title: () => import(/* webpackPrefetch: true */ "../common/Title"),
    Loader: () => import(/* webpackPrefetch: true */ "../common/Loader"),
    Button: () => import(/* webpackPrefetch: true */ "../common/Button"),
    IconButton: () =>
      import(/* webpackPrefetch: true */ "../common/IconButton"),
  },
  data: () => ({
    title: "User Access Management (UAM)",
    isLoader: true,
    size: "5px",
    users: null,
    buttonType: "edit",
    popupTitle: null,
    serachQuery: "",
  }),
  created() {
    setTimeout(() => {
      if (this.user.role === "Admin") {
        this.getUsers();
      } else {
        this.$router.push({ path: "/unauthorised" }).catch(() => {});
      }
    }, 500);
  },
  computed: {
    ...mapGetters({
      user: "GET_USER_ROLE",
      userDataChanged: "GET_USER_DETAILS",
    }),
    filterUsers() {
      if (this.users) {
        return this.users.filter((user) => {
          const name = user.UserName.toLowerCase();
          const email = user.UserEmail.toLowerCase();
          const searchTerm = this.serachQuery.toLowerCase();
          return name.includes(searchTerm) || email.includes(searchTerm);
        });
      }
      return this.users;
    },
  },
  methods: {
    ...mapMutations({ setUser: "SET_USER_DETAILS" }),
    highlightMatches(text) {
      const matchExists = text
        .toLowerCase()
        .includes(this.serachQuery.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.serachQuery, "ig");
      return text.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      );
    },
    async getUsers() {
      try {
        const { data } = await uAmService.getUsers();
        this.users = data;
        this.isLoader = false;
      } catch (error) {
        loggerService.logError(error);
        this.isLoader = false;
        this.users = [];
      }
    },
    editUser(user) {
      this.setUser({
        UserName: user.UserName,
        UserEmail: user.UserEmail,
        UserId: user.UserId,
        IsCreateUser: false,
        selectedCountry:
          user.ActiveCountries === null
            ? []
            : user.UserCountries.map((i) => {
                return i.CountryId;
              }),
        selectedRole: user.RoleId === 0 ? null : user.RoleId,
        selectedStatus: user.Active == 0 ? 2 : user.Active,
        isDiabled: true,
      });

      this.popupTitle = "Edit User";
      Bus.$emit("open", {
        component: User,
        title: this.popupTitle,
        size: "md",
        closeOnClick: false,
      });
    },
    addUser() {
      this.setUser({
        UserName: null,
        UserEmail: null,
        UserId: 0,
        IsCreateUser: true,
        selectedCountry: [],
        selectedRole: null,
        selectedStatus: null,
        isDiabled: false,
      });
      this.popupTitle = "Create User";
      Bus.$emit("open", {
        component: User,
        title: this.popupTitle,
        size: "md",
        closeOnClick: false,
      });
    },
    async removeUser(removeUser) {
      const user = {
        UserName: removeUser.UserName,
        UserEmail: removeUser.UserEmail,
        UserId: removeUser.UserId,
        RoleId: removeUser.RoleId,
        ActiveCountries: removeUser.UserCountries.map((i) => {
          return i.CountryId;
        }).toString(),
        AdminEmail: authentication.getUserEmail(),
        Active: false,
        IsCreateUser: false,
      };
      try {
        const { data } = await userService.addOrUpdateUser(user);
        loggerService.logSuccess("User Removed Successfully");
        console.log(data);
      } catch (error) {
        loggerService.logError(error);
      }
      this.setUser(user);
    },
  },
  watch: {
    userDataChanged() {
      this.getUsers();
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  tbody tr {
    &:nth-child(even) {
      background-color: #f4faff;
    }
    &:nth-child(odd) {
      background-color: #fff;
    }
  }
}
.header-color {
  background: #cbe4fc;
  font-size: 1rem;
  font-weight: bold;
}
.people-icon svg {
  fill: #a09d9d;
}
button {
  padding: 4px 10px 6px 10px;
  font-size: 1rem;
  font-weight: 600;
  outline: 0;
  border-radius: 3px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: auto;
  outline: none;
  color: #fff;
}
.bg-gray-300 {
  background: #d2d6de;
}
.bg-gray-200 {
  background: #dcdde0;
  color: #999;
}
.bg-gray-300.active {
  background: #3f6ad8;
}
.bg-gray-200.active {
  background: #537be0;
  color: #fff;
}
.bg-gray-300.visited {
  background: #00a65a;
}
.bg-gray-200.visited {
  background: #47b281;
  color: #fff;
}
.seach-icon svg {
  width: 1.2rem;
  fill: #bbb;
  position: absolute;
  margin-left: 6px;
  margin-top: 8px;
}
.table-scroll {
  max-height: 380px;
  overflow-y: auto;
  overflow-x: hidden;
}
.bg {
  background: #fff;
}
.title {
  color: #337ab7;
  text-align: left;
  font-size: 1.2rem;
  font-weight: 600;
}
.actions div {
  margin-left: 10px;
}
</style>
